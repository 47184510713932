export const exhibitionData = {
  galleries: [
    {
      photographer: "Emma Taylor",
      galleryId: "emma-taylor",
      twitter: "Em_taylor17",
      website: "https://emmataylor-17.wixsite.com/mysite",
      intro: `
      <p>Emma is a recent sustainable development graduate living in the Southside of Glasgow with her two preschoolers. </p>
       
      <p>An amateur photographer primarily capturing and sharing the biodiversity that her local parks and woodlands possess. From the start of the initial lockdown, her family set out and continues to work towards their John Muir Award for conservation. This includes identifying species throughout Castlemilk park. </p>
       
      <p>When not volunteering, King's park is a favourite haunt for the family. Here is where the children have space to run, climb, play and picnic. Her photos aim to highlight the importance Glasgow's parks have on the wellbeing of local families and biodiversity.</p>
       
      <p>She chose these specific photos to highlight how green, healthy and wild Glasgow really is. They are all taken within Castlemilk and King's park, yet she stresses, the vast majority of Glasgow's green space holds similar qualities and should be nationally celebrated.</p>`,
      directory: "emma-taylor",
      photos: [
        {
          file: "3CD6F6CE-6B60-4500-9126-B65428F29016.jpeg",
          plaque: "A place for diminished species to thrive - Castlemilk Park",
          orientation: 1,
          wToHRatio: 1.3251982916412446,
          hToWRatio: 0.7546040515653776,
        },
        {
          file: "1841EA7F-5D7F-4636-811D-425B392E8D1F.jpeg",
          plaque: "A place for rare finds - Castemilk Park",
          orientation: 1,
          wToHRatio: 1.3914421553090333,
          hToWRatio: 0.7186788154897494,
        },
        {
          file: "B0DD3BF7-CA90-46C9-A9AB-9CE6A6E6A108.jpeg",
          plaque: "A place to connect - King’s Park",
          orientation: 1,
          wToHRatio: 1.3975762314308053,
          hToWRatio: 0.7155244755244755,
        },
        {
          file: "A62A26DC-2718-4F3E-B2C0-4E0EAE63E1B3.jpeg",
          plaque: "A place to feel free - King’s Park",
          orientation: 1,
          wToHRatio: 0.75,
          hToWRatio: 1.3333333333333333,
        },
        {
          file: "C3FB964A-27F9-4FD4-B826-522ED61829F7.jpeg",
          plaque: "A place for our pollinators - Castlemilk Park",
          orientation: 1,
          wToHRatio: 1.3333333333333333,
          hToWRatio: 0.75,
        },
        {
          file: "C4B54B2C-0A19-42B0-9DD7-C85E190D68E0.jpeg",
          plaque: "A place for wilder play - King’s Park",
          orientation: 1,
          wToHRatio: 1.3333333333333333,
          hToWRatio: 0.75,
        },
        {
          file: "4EB91256-8C25-4593-B9F0-C3A3598EB053.jpeg",
          plaque: "A place to imagine in - Castlemilk Park",
          orientation: 1,
          wToHRatio: 1.4889217134416544,
          hToWRatio: 0.6716269841269841,
        },
        {
          file: "941A317D-61AE-4EA3-8107-C59616AC6D34.jpeg",
          plaque: "A place to reflect - Castlemilk Park",
          orientation: 1,
          wToHRatio: 0.7167040079661439,
          hToWRatio: 1.3952761375477596,
        },
        {
          file: "24ABB29B-6013-478E-95E7-73F37E5D1740.jpeg",
          plaque: "A place for our senses - Castlemilk Park",
          orientation: 1,
          wToHRatio: 1.3333333333333333,
          hToWRatio: 0.75,
        },
        {
          file: "B88873F4-3E37-4E29-B1F9-02B65ECE10E6.jpeg",
          plaque: "A place to protect - Castlemilk park",
          orientation: 1,
          wToHRatio: 1.4592538935168418,
          hToWRatio: 0.6852817076197567,
        },
      ],
    },
    {
      photographer: "Rider Glasgow",
      galleryId: "rider-glasgow",
      twitter: "RiderGlasgow",
      intro: `<p>We've followed Rider Glasgow on twitter over the last year.  We've loved the photos so were delighted to be allowed to include some in this exhibition.
      </p><p>
      Rider Glasgow's fantastic twitter profile provides "The (unofficial) view from a Glasgow Deliveroo rider's saddle" and it's this unique perspective which make his photos such a great snapshot of Glasgow. 
      </p><p>
      Everything from Friday night streets, to parks and green spaces and the hidden garden's and closes dropping off takeaways the photos really bring the beauty and the personality of Glasgow to life. </p>`,
      directory: "glasgow-deliveroo",
      photos: [
        {
          file: "BeastFromTheEast.jpg",
          plaque: "",
          orientation: -1,
          wToHRatio: 0.75,
          hToWRatio: 1.3333333333333333,
        },
        {
          file: "Ee6z_qSXoAA829g.jpg",
          plaque: "",
          orientation: -1,
          wToHRatio: 0.72,
          hToWRatio: 1.3888888888888888,
        },
        {
          file: "ElG8Z0CWMAADXGU.jpg",
          plaque: "",
          orientation: -1,
          wToHRatio: 0.75,
          hToWRatio: 1.3333333333333333,
        },
        {
          file: "FromPinnacle.jpg",
          plaque: "",
          orientation: -1,
          wToHRatio: 0.7092857142857143,
          hToWRatio: 1.4098690835850958,
        },
        {
          file: "GreenheadStreetNearGlasgowGreen.jpg",
          plaque: "",
          orientation: -1,
          wToHRatio: 0.7614285714285715,
          hToWRatio: 1.3133208255159474,
        },
        {
          file: "five-thirty-Friday-night.jpg",
          plaque: "",
          orientation: -1,
          wToHRatio: 0.75,
          hToWRatio: 1.3333333333333333,
        },
        {
          file: "SauchiehallStreetAvenue.jpg",
          plaque: "",
          orientation: -1,
          wToHRatio: 0.7642857142857142,
          hToWRatio: 1.308411214953271,
        },
        {
          file: "TenementCloseGarnethill.jpg",
          plaque: "",
          orientation: -1,
          wToHRatio: 1.3335714285714286,
          hToWRatio: 0.7498660953401178,
        },
        // {
        //   file: "May2020.jpg",
        //   plaque:"", orientation: -1,
        //   wToHRatio: 1.3335714285714286,
        //   hToWRatio: 0.7498660953401178,
        // },
        {
          file: "PhoenixFlowers.jpg",
          plaque: "",
          orientation: -1,
          wToHRatio: 0.7292857142857143,
          hToWRatio: 1.3712047012732616,
        },
        {
          file: "TheBridgeToNowhere.jpg",
          plaque: "",
          orientation: -1,
          wToHRatio: 0.6664285714285715,
          hToWRatio: 1.5005359056806002,
        },
        {
          file: "KelvinWayInLockdown.jpg",
          plaque: "",
          orientation: -1,
          wToHRatio: 0.75,
          hToWRatio: 1.3333333333333333,
        },
        {
          file: "GreyfriarsGarden.jpg",
          plaque: "",
          orientation: -1,
          wToHRatio: 0.75,
          hToWRatio: 1.3333333333333333,
        },
      ],
    },
    {
      photographer: "Alisdair Woodburn",
      galleryId: "alisdair-woodburn",
      twitter: "Naburn2",
      facebook: "AlisdairsPhotos",
      instagram: "alisdairs_photos",
      intro: `<p>Alisdair has been a supporter of the Glasgow National Park City since the beginning.  His photos have been an inspiration since we started and he has been kind enough to allow us to use his photos on our website and now in this exhibition. 
      </p><p>
      Alisdair is a prolific photographer of the city and alongside sharing the personality and the humour of the city, many of his photos capture the spectacular natural beauty of sites like the Clyde, Glasgow Green and many of the City's parks and green spaces.  
      </p><p>
      It's no surprise that nearly 4000 people follow his Alisdair's photos facebook page - there you can enjoy many more of his beautiful photos of Glasgow.</p>`,
      directory: "alisdair-woodburn",
      photos: [
        {
          file: "pic-01.jpg",
          wToHRatio: 0.67,
          hToWRatio: 1.5,
          location: "",
          caption: "",
        },
        {
          file: "pic-02.jpg",
          wToHRatio: 0.67,
          hToWRatio: 1.5,
          location: "",
          caption: "",
        },
        {
          file: "pic-03.jpg",
          wToHRatio: 0.67,
          hToWRatio: 1.5,
          location: "",
          caption: "",
        },
        {
          file: "pic-04.jpg",
          wToHRatio: 0.67,
          hToWRatio: 1.5,
          location: "",
          caption: "",
        },
        {
          file: "pic-05.jpg",
          wToHRatio: 0.67,
          hToWRatio: 1.5,
          location: "",
          caption: "",
        },
        {
          file: "pic-06.jpg",
          wToHRatio: 0.67,
          hToWRatio: 1.5,
          location: "",
          caption: "",
        },
        {
          file: "pic-07.jpg",
          wToHRatio: 0.67,
          hToWRatio: 1.5,
          location: "",
          caption: "",
        },
        {
          file: "pic-08.jpg",
          wToHRatio: 0.67,
          hToWRatio: 1.5,
          location: "",
          caption: "",
        },
        {
          file: "pic-09.jpg",
          wToHRatio: 0.67,
          hToWRatio: 1.5,
          location: "",
          caption: "",
        },
        {
          file: "pic-10.jpg",
          wToHRatio: 0.67,
          hToWRatio: 1.5,
          location: "",
          caption: "",
        },

        {
          file: "pic-11.jpg",
          wToHRatio: 0.67,
          hToWRatio: 1.5,
          location: "",
          caption: "",
        },
        {
          file: "pic-12.jpg",
          wToHRatio: 0.67,
          hToWRatio: 1.5,
          location: "",
          caption: "",
        },
        {
          file: "pic-13.jpg",
          wToHRatio: 0.67,
          hToWRatio: 1.5,
          location: "",
          caption: "",
        },
        {
          file: "pic-14.jpg",
          wToHRatio: 0.67,
          hToWRatio: 1.5,
          location: "",
          caption: "",
        },
        {
          file: "pic-15.jpg",
          wToHRatio: 0.67,
          hToWRatio: 1.5,
          location: "",
          caption: "",
        },
        {
          file: "pic-16.jpg",
          wToHRatio: 0.67,
          hToWRatio: 1.5,
          location: "",
          caption: "",
        },
      ],
    },
    {
      photographer: "Liam Paterson",
      galleryId: "liam-paterson",
      directory: "liam-paterson",
      twitter: "to_glasgow",
      intro: `
      <p>I started my Twitter feed as Walk to Work Glasgow in April 2019. 
      </p>
      <p>
      I’m fortunate to be able to walk to work mostly along the River Kelvin and through the Botanic Gardens. It was spring and I was gradually starting to take more notice of the season changing around me as I walked, and often
      stopping to take photos with my phone. I was using Twitter at work at the time, so I decided to start
      my own feed as a daily journal of the walk. 
      </p>
      <p>
      The first images were the obvious stuff - sun on the river, tree shadows, pink rhododendrons bursting out - but I wanted to see a bit more detail. I
      bought a couple of clip-on macro lenses for the phone and then my focus expanded to tiny water droplets on feathers and petals, insects, ice and frost structures, spiders’ webs. I’m fascinated by combinations of colours, shapes and textures and the infinitely varied effects of light.
      </p>
      <p>
      Rather ironically, I’ve been working from home since last March rather than walking to work, but
      I’ve kept up my daily walks in the park. The fact that Glasgow still has a good network of public
      parks and open spaces will have been a great help to many during the course of the pandemic - it
      has been much busier along the Kelvin at times in recent months. 
      </p>
      <p>
      This period of lockdown has
      emphasised how important it is for people in such a large city to have access to safe places to
      exercise, to slow down and stop, to just breathe. You can choose to be solitary in a park, or be in
      company - I’ve met a few people from my Twitter feed while taking photos, and I even had
      someone coming over to check I wasn’t having a heart attack while I was kneeling in the wet grass
      getting close-up shots!
      </p>
      <p>
      The images I’ve chosen for this exhibition represent a chronological year of walking, from January
      to December. They aren’t obviously ‘photos of Glasgow”, as they are all close-ups - but they show
      the beauty and variety of nature that is always there around us, whether on a dull day or when the
      sun is shining.</p>`,
      photos: [
        {
          file: "01_January.jpg",
          plaque: "January",
          orientation: 1,
          wToHRatio: 0.9737354085603113,
          hToWRatio: 1.026973026973027,
        },
        {
          file: "02_February.jpg",
          plaque: "February",
          orientation: 1,
          wToHRatio: 0.7048917401764234,
          hToWRatio: 1.4186575654152447,
        },
        {
          file: "03_March.jpg",
          plaque: "March",
          orientation: 1,
          wToHRatio: 0.6366742596810934,
          hToWRatio: 1.5706618962432917,
        },
        {
          file: "04_April.jpg",
          plaque: "April",
          orientation: 3,
          wToHRatio: 0.75,
          hToWRatio: 1.3333333333333333,
        },
        {
          file: "05_May.jpg",
          plaque: "May",
          orientation: 1,
          wToHRatio: 0.8439542483660131,
          hToWRatio: 1.1848983543078413,
        },
        {
          file: "06_June.jpg",
          plaque: "June",
          orientation: -1,
          wToHRatio: 0.75,
          hToWRatio: 1.3333333333333333,
        },
        {
          file: "07_July.jpg",
          plaque: "July",
          orientation: 1,
          wToHRatio: 0.7333333333333333,
          hToWRatio: 1.3636363636363635,
        },
        {
          file: "08_August.jpg",
          plaque: "August",
          orientation: 1,
          wToHRatio: 1.3334776959722823,
          hToWRatio: 0.7499188048067554,
        },
        {
          file: "09_September.jpg",
          plaque: "September",
          orientation: 1,
          wToHRatio: 0.9899451553930531,
          hToWRatio: 1.0101569713758078,
        },
        {
          file: "10_October.jpg",
          plaque: "October",
          orientation: 1,
          wToHRatio: 1.0346172598732326,
          hToWRatio: 0.9665409990574929,
        },
        {
          file: "11_November.jpg",
          plaque: "November",
          orientation: 1,
          wToHRatio: 0.66677471636953,
          hToWRatio: 1.4997569275644143,
        },
        {
          file: "12_December.jpg",
          plaque: "December",
          orientation: -1,
          wToHRatio: 0.75,
          hToWRatio: 1.3333333333333333,
        },
      ],
    },
    {
      photographer: "Becky Duncan",
      galleryId: "becky-duncan",
      twitter: "open_aye",
      website: "https://www.openaye.co.uk/",
      directory: "becky-duncan",
      biogPic: "biogPic_250x167.jpg",
      intro: `
      <p>I see Glasgow as a Phenomenal Park City, and I know many others do, too.
      </p>
      <p>
      The tree canopy of Kelvin Way. Kelvingrove’s green beach, in the summer sun. The Kelvin Walkway, where herons stalk walkers. The Botanic’s bold tree hunt trail. Petrified stumps in Victoria Park. Canoes on the canal. Glasgow Green: where marchers come to rest. A bird’s holiday resort on Hogganfield, leading to the 7 Lochs trails of wonder. Alexandra Park, on parade. The Cunningar kids on a Loop de Loop.  Auchenshuggle’s shrug to the motorway. Malls Mire’s community hug. The Linn Park fish that swim up the way. The majestic heights and sights of Cathkin Braes. Movers, makers, skaters and non-shavers at Queens Park. The kites flying around Bellahouston. And the biggest (and best??).. Pollok Country Park, where history resides in the North Woods and the Great Beech refuses to die. 
      </p>
      <p>
      And then there’s all the wee local places, where folk meet, dogs dander and kids play.  We can all play. We can all escape. Breathe the air. See the sky. Feel the city, its communities, cultures and it’s dreams. Live.
      </p>
      <p>
      I wish to look after these places, so that they can grow, be bio-diverse and reverse decline. We all need Our Dear Green Place. It needs us. Let’s protect and invest in Glasgow National Park City. For COP26 and beyond. So our dear green place may be an appreciated, thriving space, for good. 
      </p>
      <p>
      Becky Duncan – Open Aye
      </p>
      <footer>
      Becky Duncan retains the copyright of all these images, but is happy for GNPC to use them for 1-2 years to promote the work being done. <br/>
      All of these images were taken in Glasgow parks.  
      </footer>
     `,
      photos: [
        {
          file: "Cunningar-Loop-05.jpg",
          orientation: 1,
          wToHRatio: 0.6671428571428571,
          hToWRatio: 1.4989293361884368,
        },
        {
          file: "7-Lochs-Vols1-01-copy.jpg",
          orientation: 1,
          wToHRatio: 0.6664285714285715,
          hToWRatio: 1.5005359056806002,
        },
        {
          file: "NKBL_22.jpg",
          orientation: 1,
          wToHRatio: 0.6664285714285715,
          hToWRatio: 1.5005359056806002,
        },
        {
          file: "FCS-EFL-62.jpg",
          orientation: 1,
          wToHRatio: 0.6664285714285715,
          hToWRatio: 1.5005359056806002,
        },
        {
          file: "FCS-Int-Peace-Day-16-sept16-4.jpg",
          orientation: 1,
          wToHRatio: 0.6664285714285715,
          hToWRatio: 1.5005359056806002,
        },
        {
          file: "SNH_180.jpg",
          orientation: 1,
          wToHRatio: 0.6664285714285715,
          hToWRatio: 1.5005359056806002,
        },
        {
          file: "SHA-Parkour18_02-copy.jpg",
          orientation: 1,
          wToHRatio: 0.6664285714285715,
          hToWRatio: 1.5005359056806002,
        },
        {
          file: "DSC_9576.jpg",
          orientation: 1,
          wToHRatio: 1.5,
          hToWRatio: 0.6666666666666666,
        },
        {
          file: "SNH-Autumn-058.jpg",
          orientation: 1,
          wToHRatio: 1.5,
          hToWRatio: 0.6666666666666666,
        },
        {
          file: "NKBL_40.jpg",
          orientation: 1,
          wToHRatio: 1.5,
          hToWRatio: 0.6666666666666666,
        },
      ],
    },

    {
      photographer: "Michael M Sweeney",
      galleryId: "michael-sweeney",
      facebook: "horticulture11",
      directory: "michael-sweeney",
      intro: `<p>
      Hi my name is Michael M Sweeney, I’m a wildlife photographer from the East End of Glasgow. I am so grateful for the open spaces we have in our city. They allow me get out and about every day to explore these places of great wildlife diversity. 
      </p>
      <p>
      I’m in love with photography and the open spaces we have - such as my local Seven Lochs Wetland Park. It brings me a lot of self-healing and well-being – and I hope that by sharing my photos I can inspire other’s love for nature and the wildlife around them.</p>`,
      photos: [
        {
          file: "850_6360.jpg",
          orientation: -1,
          wToHRatio: 1.251,
          hToWRatio: 0.7993605115907274,
        },
        {
          file: "108382977_10217118436338030_899329293463790841_o.jpg",
          orientation: -1,
          wToHRatio: 0.5622745597284108,
          hToWRatio: 1.778490566037736,
        },
        {
          file: "850_6899.jpg",
          orientation: -1,
          wToHRatio: 0.9996922129886119,
          hToWRatio: 1.000307881773399,
        },
        {
          file: "12017421_10205043284706786_2494285890961287938_o.jpg",
          orientation: -1,
          wToHRatio: 0.7539582809751194,
          hToWRatio: 1.3263333333333334,
        },
        {
          file: "DSC_60-copy-3.jpg",
          orientation: -1,
          wToHRatio: 1,
          hToWRatio: 1,
        },
        {
          file: "pib.jpg",
          orientation: -1,
          wToHRatio: 0.8643333333333333,
          hToWRatio: 1.1569610489780178,
        },
        {
          file: "850_5732.jpg",
          orientation: -1,
          wToHRatio: 1,
          hToWRatio: 1,
        },
        {
          file: "AAA_2364.jpg",
          orientation: -1,
          wToHRatio: 1.009433962264151,
          hToWRatio: 0.9906542056074766,
        },
        {
          file: "DSC_4810-23copy-2.jpg",
          orientation: -1,
          wToHRatio: 0.6666666666666666,
          hToWRatio: 1.5,
        },
        {
          file: "AAA_24.jpg",
          orientation: 1,
          wToHRatio: 0.6801152737752162,
          hToWRatio: 1.4703389830508475,
        },
      ],
    },
    {
      photographer: "Monique Campbell",
      galleryId: "monique-campbell",
      directory: "monique-campbell",
      twitter: "moniquelvct",
      facebook: "moniquelaralisephotography",
      website: "https://moniquelaralisephotography.com",
      intro: `<p>
      I love taking photographs when I go for my ‘daily walk’. Living in Glasgow for ten years I got to know lots of the beautiful and quirky green spaces we are lucky enough to enjoy, through learning to take photographs. From exploring macro photography through extreme close-ups of water droplets after a summer rain to attempts to master shutter speed through obsessively waiting for the perfect bird in flight moment.
      </p>
      <p>
The appearance of the cygnets on Queens Park pond became a yearly highlight, as I’d catch glimpses of them on my cycle to work and then grab my camera and go back to the park in the evening. There’s a lovely community of local people who do the same and I’ve always enjoyed sharing my love of photography with folk who get as excited as I do about capturing a tiny wing fluttering.
</p>
      <p>
I moved to East Kilbride a couple of years ago and my camera has helped me navigate my new surroundings. I’ve fallen in love with the Loch at James Hamilton Heritage Park where most of these photos are taken. From the cheeky wee goslings in the late spring to the frozen wonder of this year’s intense ice and snow.
</p>
      <p>
Taking photographs every day makes me feel better – calmer, more hopeful and inspired. There’s a whole other world that opens when you look at everyday things from a different angle. Let’s protect our parks and green spaces so the next generation can continue to enjoy the wonder and wellbeing they inspire.
      </p>`,
      photos: [
        {
          file: "JamesHamiltonHeritagePark-1.JPG",
          orientation: -1,
          wToHRatio: 1.4979724249797242,
          hToWRatio: 0.6675690308608554,
        },
        {
          file: "JamesHamiltonHeritagePark-2.JPG",
          orientation: -1,
          wToHRatio: 0.6675531914893617,
          hToWRatio: 1.49800796812749,
        },
        {
          file: "JamesHamiltonHeritagePark-3.JPG",
          orientation: -1,
          wToHRatio: 0.6681830345196682,
          hToWRatio: 1.4965959150981178,
        },
        {
          file: "JamesHamiltonHeritagePark-4.JPG",
          orientation: -1,
          wToHRatio: 0.6676241973639743,
          hToWRatio: 1.4978486459124272,
        },
        {
          file: "JamesHamiltonHeritagePark-5.JPG",
          orientation: 1,
          wToHRatio: 0.6676136363636364,
          hToWRatio: 1.4978723404255319,
        },
        {
          file: "Glasgow-Botanics-2017_MC.JPG",
          orientation: 1,
          wToHRatio: 0.6648989061982101,
          hToWRatio: 1.503988035892323,
        },
        {
          file: "James-Hamilton-Heritage-Park-EK-2021_MC-(1).JPG",
          orientation: 1,
          wToHRatio: 0.6675671112611852,
          hToWRatio: 1.497976732422863,
        },
        {
          file: "Stewartfield-EK-2021_MC.JPG",
          orientation: 1,
          wToHRatio: 0.6676829268292683,
          hToWRatio: 1.4977168949771689,
        },
        {
          file: "James-Hamilton-Heritage-Park-EK-2021_MC-(3).JPG",
          orientation: 1,
          wToHRatio: 1.497624703087886,
          hToWRatio: 0.6677240285487708,
        },
        {
          file: "Queens-Park-2019_MC.JPG",
          orientation: 1,
          wToHRatio: 0.667601683029453,
          hToWRatio: 1.4978991596638656,
        },
        {
          file: "James-Hamilton-Heritage-Park-EK-2021_MC-(2).JPG",
          orientation: 1,
          wToHRatio: 0.6675682986205032,
          hToWRatio: 1.4979740680713127,
        },
        {
          file: "Queens-Park-2019_MC(3).JPG",
          orientation: 1,
          wToHRatio: 0.6676136363636364,
          hToWRatio: 1.4978723404255319,
        },
        {
          file: "James-Hamilton-Heritage-Park-EK-2021_MC-(6).JPG",
          orientation: 1,
          wToHRatio: 0.6675929142063216,
          hToWRatio: 1.4979188345473464,
        },
        {
          file: "James-Hamilton-Heritage-Park-EK-2021_MC-(5).JPG",
          orientation: 1,
          wToHRatio: 0.6675557248999809,
          hToWRatio: 1.4980022831050228,
        },
        {
          file: "James-Hamilton-Heritage-Park-EK-2021_MC-(4).JPG",
          orientation: 1,
          wToHRatio: 0.6675598727181377,
          hToWRatio: 1.4979929754139487,
        },
        {
          file: "Queens-Park-2019_MC(2).JPG",
          orientation: 1,
          wToHRatio: 0.6675531914893617,
          hToWRatio: 1.49800796812749,
        },
        {
          file: "Queens-Park-2017_MC.JPG",
          orientation: 1,
          wToHRatio: 0.6675531914893617,
          hToWRatio: 1.49800796812749,
        },
      ],
    },
    {
      photographer: "Neil Young",
      galleryId: "neil-young",
      directory: "neil-young",
      twitter: "neilyoungphot0s",
      facebook: "Neilyoungphotos-428555393823680",
      youtube: "UC5YSvj0CO9YYQUxa4EueyTA",
      intro: `<p>
      I am currently the environmental coordinator for a housing association in the east end of Glasgow, the role is to achieve net-zero carbon emissions through various projects from composting, growing vegetables and forming more wild flowering areas. This should reduce emissions by reducing the amount of grass cutting in the area, attract more wildlife,  and make the area more attractive looking by adding some colour.  
      </p>
      <p>
      Since moving back to Glasgow in the early 90's I have been photographing this amazing city captivated by the architecture it's grid layout, open spaces, stunning parks and diverse and caricaturistic population. 
      </p>
      <p>
      The series of pictures I have included were, taken during a very uncertain time for me as I was going through chemotherapy for Leukemia which was just recently diagnosed, Not knowing what laid ahead for me I used this time to firstly, get some exercise and secondly to get out with my camera to capture the autumn colours of Glasgow parks.
      </p>
      <p>
      I think the prospect of Glasgow being a National City park is an excellent idea that would make Glasgow a much better environment for all of Glasgow's inhabitants to live in. 
      </p>`,
      photos: [
        {
          file: "IMG_0198.JPG",
          plaque: "Taking the scenic way home - Glasgow Green",
          orientation: 1,
          wToHRatio: 0.6666666666666666,
          hToWRatio: 1.5,
        },
        {
          file: "IMG_4249.JPG",
          plaque: "Strolling and Strumming - The Botanics",
          orientation: 1,
          wToHRatio: 0.6666666666666666,
          hToWRatio: 1.5,
        },
        {
          file: "IMG_4252.JPG",
          plaque:
            "You don't have to travel far for full autumnal colours - The Botanics",
          orientation: 1,
          wToHRatio: 0.6666666666666666,
          hToWRatio: 1.5,
        },
        {
          file: "IMG_4253.JPG",
          plaque: "Emesred in technology and nature - The Botanics",
          orientation: 1,
          wToHRatio: 0.6666666666666666,
          hToWRatio: 1.5,
        },
        {
          file: "IMG_4261.JPG",
          plaque: "Stroll under the canopy - The Botanics",
          orientation: 1,
          wToHRatio: 0.6666666666666666,
          hToWRatio: 1.5,
        },
        {
          file: "IMG_4301.JPG",
          plaque: "Swans hogging the pond - Victoria park",
          orientation: 1,
          wToHRatio: 0.6666666666666666,
          hToWRatio: 1.5,
        },
        {
          file: "IMG_4319.JPG",
          plaque: "Afternoon stroll - Victoria Park ",
          orientation: 1,
          wToHRatio: 0.6666666666666666,
          hToWRatio: 1.5,
        },
        {
          file: "IMG_4325.JPG",
          plaque: "Autumn Explosion -  Victoria Park",
          orientation: 1,
          wToHRatio: 0.6666666666666666,
          hToWRatio: 1.5,
        },
        {
          file: "IMG_4568.JPG",
          plaque: "The hectic city!!!! - River Kelvin",
          orientation: 1,
          wToHRatio: 0.6666666666666666,
          hToWRatio: 1.5,
        },
        {
          file: "IMG_4657.JPG",
          plaque: "Sun filtering through the trees - Kelvingrove",
          orientation: 1,
          wToHRatio: 0.6666666666666666,
          hToWRatio: 1.5,
        },
        {
          file: "IMG_4687.JPG",
          plaque: "Slacklining in the park - Kelvingrove",
          orientation: 1,
          wToHRatio: 0.6666666666666666,
          hToWRatio: 1.5,
        },
        {
          file: "IMG_4761.JPG",
          plaque: "Bridge over the White cart",
          orientation: 1,
          wToHRatio: 0.6666666666666666,
          hToWRatio: 1.5,
        },
      ],
    },
    {
      photographer: "Punk Wildlife",
      galleryId: "punk-wildlife",
      directory: "punk-wildlife",
      twitter: "PunkWildlife",
      instagram: "punkwildlife",
      intro: `<p>I started taking photos of wildlife two years ago as an occasional hobby and got more invested as the first lockdown was coming in. I had just moved to Glasgow so I took the opportunity to get my exercise in each day by heading out at sunrise to have a look at my local spots for wildlife. 
      </p>
      <p>
      With no people around, I found some great opportunities to take photographs, some of which have since been shared in media outlets across the world from the BBC to national newspapers. For someone who is still learning how to use his camera, I find myself lost for words to explain how happy this makes me! 
      </p>
      <p>
      All of these photos were taken close to home in the east end of Glasgow with the majority being snapped at the wonderful Hogganfield Loch. 
      </p>
      <p>
      I feel incredibly lucky to have so many green spaces on my doorstep. This city is bursting with nature. Get out there and appreciate it! </p>`,
      photos: [
        {
          file: "alexandrapark3.png",
          orientation: -2,
          wToHRatio: 0.9686333084391336,
          hToWRatio: 1.0323824209714727,
        },
        {
          file: "hog8.png",
          orientation: -2,
          wToHRatio: 0.663168415792104,
          hToWRatio: 1.507912584777694,
        },
        {
          file: "hog9.png",
          orientation: -2,
          wToHRatio: 1.1028537455410226,
          hToWRatio: 0.9067385444743935,
        },
        {
          file: "cardowanmoss.png",
          orientation: -2,
          wToHRatio: 0.6655145929339478,
          hToWRatio: 1.502596653202539,
        },
        {
          file: "hog0.png",
          orientation: -2,
          wToHRatio: 0.8760484622553588,
          hToWRatio: 1.1414893617021276,
        },
        {
          file: "Hogganfield7.png",
          orientation: -2,
          wToHRatio: 0.6665468918433345,
          hToWRatio: 1.5002695417789758,
        },
        {
          file: "AlexandraPark2.png",
          orientation: -2,
          wToHRatio: 0.6674876847290641,
          hToWRatio: 1.4981549815498154,
        },
        {
          file: "hogganfield3.png",
          orientation: -2,
          wToHRatio: 0.6570306923625981,
          hToWRatio: 1.5219989136338947,
        },
        {
          file: "RiverKelvin1.png",
          orientation: -2,
          wToHRatio: 0.6401662049861496,
          hToWRatio: 1.5620943314582432,
        },
        {
          file: "hog7.png",
          orientation: -2,
          wToHRatio: 0.6944877795111805,
          hToWRatio: 1.4399101460127293,
        },
        {
          file: "Hogganfield6.png",
          orientation: -2,
          wToHRatio: 0.6958238105131934,
          hToWRatio: 1.4371454165422515,
        },
        {
          file: "Hogganfield2.png",
          orientation: -2,
          wToHRatio: 0.6586442923382645,
          hToWRatio: 1.5182701977874622,
        },
        {
          file: "Hogganfield4.png",
          orientation: -2,
          wToHRatio: 0.8181818181818182,
          hToWRatio: 1.2222222222222223,
        },
        {
          file: "mugdock1.png",
          orientation: -2,
          wToHRatio: 0.89694254085398,
          hToWRatio: 1.1148986188657066,
        },
        {
          file: "hogaanfield000.png",
          orientation: -2,
          wToHRatio: 0.6668054977092878,
          hToWRatio: 1.499687695190506,
        },
        {
          file: "alexandrapark1.png",
          orientation: -2,
          wToHRatio: 0.6658918005071851,
          hToWRatio: 1.5017454776261505,
        },
      ],
    },
    {
      photographer: "Morag Perkins",
      galleryId: "morag-perkins",
      directory: "morag-perkins",
      twitter: "moragperkins",
      facebook: "moragperkinsphoto",
      website: "https://www.moragperkinsphoto.com/index.html",
      intro: `<p>Glasgow’s parks and green spaces are hugely important to me - and have become even more so in the past year! When the coronavirus pandemic hit, my world shrunk dramatically, and since March 2020 I haven’t been able to go anywhere outside walking range of my home in the West End.
      </p>
      <p>
      I’m a photographer, and during the pandemic I have been increasingly using photography as a sort of mindfulness. When I need to clear my head, I will load a roll of film into an old camera and head out for a walk. I’m all about the tiny details and changing seasons; I’ve got to know almost every little corner of my local area. I can tell you exactly when the light will hit a particular tree in a particular way, which spot in the park the frost lingers the longest, and where the flowers will appear in spring. I can even tell you where the friendly Highland cows live! (Get yourselves to Dawsholm Park, cow fans!)
      </p>
      <p>
      I’ve chosen this particular set of photographs as my homage to the Dear Green Place. They cover the range of my little pandemic world, from the manicured exotic trees of the Botanic Gardens to the gloriously haphazard banks of the Forth & Clyde canal and the Kelvin. Creating these photographs brings me a moment of peace in a chaotic world, and I hope that they can do the same for you in some small way.
      </p>
      <footer>
      All images copyright Morag Perkins
      </footer>`,
      photos: [
        {
          file: "Morag_Perkins_01.jpg",
          orientation: -1,
          wToHRatio: 0.6692857142857143,
          hToWRatio: 1.4941302027748133,
        },
        {
          file: "Morag_Perkins_02.jpg",
          orientation: -1,
          wToHRatio: 0.6692857142857143,
          hToWRatio: 1.4941302027748133,
        },
        {
          file: "Morag_Perkins_03.jpg",
          orientation: -1,
          wToHRatio: 0.6692857142857143,
          hToWRatio: 1.4941302027748133,
        },
        {
          file: "Morag_Perkins_04.jpg",
          orientation: -1,
          wToHRatio: 0.6692857142857143,
          hToWRatio: 1.4941302027748133,
        },
        {
          file: "Morag_Perkins_05.jpg",
          orientation: -1,
          wToHRatio: 0.6692857142857143,
          hToWRatio: 1.4941302027748133,
        },
        {
          file: "Morag_Perkins_06.jpg",
          orientation: -1,
          wToHRatio: 0.6664285714285715,
          hToWRatio: 1.5005359056806002,
        },
        {
          file: "Morag_Perkins_07.jpg",
          orientation: -1,
          wToHRatio: 0.6664285714285715,
          hToWRatio: 1.5005359056806002,
        },
        {
          file: "Morag_Perkins_08.jpg",
          orientation: -1,
          wToHRatio: 0.6692857142857143,
          hToWRatio: 1.4941302027748133,
        },
        {
          file: "Morag_Perkins_09.jpg",
          orientation: -1,
          wToHRatio: 0.6692857142857143,
          hToWRatio: 1.4941302027748133,
        },
        {
          file: "Morag_Perkins_10.jpg",
          orientation: -1,
          wToHRatio: 0.6692857142857143,
          hToWRatio: 1.4941302027748133,
        },
      ],
    },
  ],
};

/*

EXAMPLE PHOTOGRAPHER DATE
©Images: Becky Duncan, for Open Aye

{
  photographer: "",
  galleryId: "",
  directory: "",
  twitter: "",
  facebook: "",
  youtube: "",
  instagram: "",
  website: "",
  intro: `<p></p>`,
  photos: [
    {
      file: "01_January.jpg",
      plaque: "January",
      orientation: 1,
      wToHRatio: 0.9737354085603113,
      hToWRatio: 1.026973026973027,
    },
  ]
}

 */
