import React from "react";

//xmlns="http://www.w3.org/2000/svg"

export const NpcLogo = ({ width = 90, className }) => {
  const style = { width, maxWidth: "28vw" };

  return (
    <svg viewBox="0 0 17.766 17.986" style={style} className={className}>
      <path
        fill="#124868"
        d="M8.457 4.228a4.228 4.228 0 01-2.614 3.909 4.228 4.228 0 01-4.61-.924A4.228 4.228 0 01.326 2.6 4.228 4.228 0 014.244 0l-.016 4.228z"
      />
      <path
        fill="#ce7c41"
        d="M0 9.53h3.449v8.456H0zm5.008 6.85a3.425 3.425 0 003.402-3.426A3.425 3.425 0 005.008 9.53z"
      />
      <path
        fill="#72b3c8"
        d="M16.576 16.698a4.228 4.228 0 01-4.596.99 4.228 4.228 0 01-2.67-3.869 4.228 4.228 0 012.557-3.946 4.228 4.228 0 014.623.857l-2.952 3.028z"
      />
      <path fill="#a8c25e" d="M9.31 0v8.457h8.456z" />
      <path
        fill="#a8c25d"
        d="M15.666 2.123l.598-.464 1.043 1.53-.598.464zm-1.044 1.066l1.044-1.53.598.464-1.044 1.53zm-.459-1.51l.227-.746 1.688.582-.227.75zM15.596 0h.737v1.89h-.737zm.255 1.515L17.54.933l.227.745-1.688.588z"
      />
    </svg>
  );
};
