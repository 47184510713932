export default function GalleryDoor() {
  return (
    <svg viewBox="0 0 407.019 776.109" width={350}>
      <path fill="#6d2015" d="M395.2 776.109V46.686H11.819v729.423z" />
      <path
        fill="#88251b"
        d="M201.509 70.686H35.819v184.618h.238c.812 0 1.47.658 1.47 1.47v24.084a1.47 1.47 0 01-1.47 1.47h-.238v184.947h.238c.812 0 1.47.658 1.47 1.47v24.084a1.47 1.47 0 01-1.47 1.47h-.238V682.04h.238c.812 0 1.47.658 1.47 1.47v24.084a1.47 1.47 0 01-1.47 1.47h-.238v67.045h165.69zm-19.863 336.945a3.46 3.46 0 013.46-3.46h5.399a3.46 3.46 0 013.46 3.46v34.831a3.46 3.46 0 01-3.46 3.46h-5.399a3.46 3.46 0 01-3.46-3.46z"
      />
      <path
        fill="#88251b"
        d="M155.278 502.731l8-7.999H73.121l8 7.999zm10.121-45.667v-76.355l-8 7.999v60.357zM326.4 336.157V100.662H254v235.496h72.4zm-62.9-225.995h53.4v216.496h-53.4zm63.779-12.5l7.999-8h-90.157l8 8zM243 91.783v253.253l8-8V99.783zM73.121 459.185h90.157l-8-7.999H81.121zm92.278-114.149V91.783l-8 8v237.253zM71 91.783v253.253l8-8V99.783zm83.399 244.374V100.662h-72.4v235.496h72.4zM91.5 110.162h53.4v216.496H91.5zm63.778-12.5l8-8H73.121l8 8zM71 380.709v76.355l8-7.999v-60.357zm2.121-33.552h90.157l-8-8H81.121zm81.278 101.029v-58.599h-72.4v58.599zM91.5 399.087h53.4v39.599H91.5zm63.778-12.5l8-7.999H73.121l8 7.999zm89.843-39.43h90.157l-7.999-8h-74.158zm-87.722 394.949l8 7.999V496.853l-8 7.999zm169.88-239.375l7.999-7.999h-90.157l8 7.999zM243 380.709v76.355l8-7.999v-60.357zm94.399 76.355v-76.355l-7.999 7.999v60.357zm-10.999-8.878v-58.599H254v58.599zm-62.9-49.099h53.4v39.599h-53.4zm73.899-54.051V91.783l-7.999 8v237.253zm-7.999 397.07l7.999 7.999V496.853l-7.999 7.999zm-2.121-355.519l7.999-7.999h-90.157l8 7.999zm-82.158 72.598h90.157l-7.999-7.999h-74.158z"
      />
      <path
        fill="#88251b"
        d="M370.962 709.063a1.47 1.47 0 01-1.47-1.47V683.51c0-.812.658-1.47 1.47-1.47h.239V494.298h-.239a1.47 1.47 0 01-1.47-1.47v-24.084c0-.812.658-1.47 1.47-1.47h.239V282.328h-.239a1.47 1.47 0 01-1.47-1.47v-24.084c0-.812.658-1.47 1.47-1.47h.239V70.686H205.51v705.423h165.691v-67.045h-.239zM210.393 407.631a3.46 3.46 0 013.46-3.46h5.4a3.46 3.46 0 013.46 3.46v34.831a3.46 3.46 0 01-3.46 3.46h-5.4a3.46 3.46 0 01-3.46-3.46z"
      />
      <path
        fill="#88251b"
        d="M253.121 744.227l-8 7.999h90.157l-7.999-7.999zm73.279-3V505.731H254v235.496zm-9.5-225.996v216.496h-53.4V515.231zM243 496.853v253.252l8-7.999V504.852zm-172 0v253.252l8-7.999V504.852zm10.121 247.374l-8 7.999h90.157l-8-7.999zm73.278-3V505.731h-72.4v235.496zM144.9 515.231v216.496H91.5V515.231z"
      />
      <path
        fill="#611f14"
        d="M371.201 494.298h4v187.741h-4zM31.819 66.686v188.618h4V70.686h165.69v705.423h4V70.686h165.692v184.618h4V66.686zm339.382 215.642h4v184.947h-4zm-339.382 0h4v184.947h-4zm0 211.97h4v187.741h-4zm339.382 214.765h4v67.045h-4zm-339.382 0h4v67.045h-4z"
      />
      <path
        fill="#3d160c"
        d="M35.819 282.328h.238a1.47 1.47 0 001.47-1.47v-24.084a1.47 1.47 0 00-1.47-1.47h-4.476a1.47 1.47 0 00-1.47 1.47v24.084c0 .812.658 1.47 1.47 1.47h.238zm0 211.97h.238a1.47 1.47 0 001.47-1.47v-24.084a1.47 1.47 0 00-1.47-1.47h-4.476a1.47 1.47 0 00-1.47 1.47v24.084c0 .812.658 1.47 1.47 1.47h.238zm0 214.765h.238a1.47 1.47 0 001.47-1.47V683.51a1.47 1.47 0 00-1.47-1.47h-4.476a1.47 1.47 0 00-1.47 1.47v24.084c0 .812.658 1.47 1.47 1.47h4.238zm335.382-453.759h-.239a1.47 1.47 0 00-1.47 1.47v24.084c0 .812.658 1.47 1.47 1.47h4.477a1.47 1.47 0 001.47-1.47v-24.084a1.47 1.47 0 00-1.47-1.47h-.238zm0 211.971h-.239a1.47 1.47 0 00-1.47 1.47v24.084c0 .812.658 1.47 1.47 1.47h4.477a1.47 1.47 0 001.47-1.47v-24.084a1.47 1.47 0 00-1.47-1.47h-.238zm0 214.764h-.239a1.47 1.47 0 00-1.47 1.47v24.084c0 .812.658 1.47 1.47 1.47h4.477a1.47 1.47 0 001.47-1.47V683.51a1.47 1.47 0 00-1.47-1.47h-4.238z"
      />
      <path
        fill="#611f14"
        d="M340.399 86.662H240v263.495h100.399zm-13.12 11h-74.158l-8-8h90.157zm-.879 3v235.496H254V100.662zm-75.4-.879v237.253l-8 8V91.783zm2.121 239.374h74.158l7.999 8h-90.157zm76.279-2.121V99.783l7.999-8v253.253z"
      />
      <path fill="#9d2b20" d="M263.5 110.162h53.4v216.496h-53.4z" />
      <path
        fill="#611f14"
        d="M340.399 491.732H240v263.495h100.399zm-13.12 10.999h-74.158l-8-8h90.157zm-73.279 3h72.4v235.496H254zm-.879 238.496h74.158l7.999 8h-90.157zm76.279-2.121V504.852l7.999-8v253.252zM243 496.853l8 8v237.253l-8 8z"
      />
      <path fill="#9d2b20" d="M263.5 515.231h53.4v216.496h-53.4z" />
      <path
        fill="#611f14"
        d="M340.399 375.588H240v86.598h100.399zm-13.12 10.999h-74.158l-8-8h90.157zm-.879 3v58.599H254v-58.599zm-75.4-.879v60.356l-8 8v-76.355zm2.121 62.478h74.158l7.999 8h-90.157zm76.279-2.121v-60.356l7.999-8v76.355z"
      />
      <path fill="#9d2b20" d="M263.5 399.087h53.4v39.599h-53.4z" />
      <path
        fill="#611f14"
        d="M168.399 86.662h-100.4v263.495h100.4zm-13.121 11H81.12l-8-8h90.158zm-.879 3v235.496h-72.4V100.662zM79 99.783v237.253l-8 8V91.783zm2.121 239.374h74.158l8 8H73.122zm76.278-2.121V99.783l8-8v253.253z"
      />
      <path fill="#9d2b20" d="M91.5 110.162h53.4v216.496H91.5z" />
      <path
        fill="#611f14"
        d="M168.399 491.732h-100.4v263.495h100.4zm-13.121 10.999H81.12l-8-8h90.157zm-73.278 3h72.4v235.496H82zm-.879 238.496h74.158l8 8H73.122zm76.278-2.121V504.852l8-8v253.252zM71 496.853l8 8v237.253l-8 8z"
      />
      <path fill="#9d2b20" d="M91.5 515.231h53.4v216.496H91.5z" />
      <path
        fill="#611f14"
        d="M168.399 375.588h-100.4v86.598h100.4zm-13.121 10.999H81.12l-8-8h90.157zm-.879 3v58.599h-72.4v-58.599zM79 388.708v60.356l-8 8v-76.355zm2.121 62.478h74.158l8 8H73.122zm76.278-2.121v-60.356l8-8v76.355z"
      />
      <path fill="#9d2b20" d="M91.5 399.087h53.4v39.599H91.5z" />
      <path fill="#4c180d" d="M5.513 6.406h395.993v8.405H5.513z" />
      <path
        fill="#3d160c"
        d="M0 0v8.406h407.019V0zm395.2 48.686h6.306v-8.405H5.513v8.405h6.306z"
      />
      <path fill="#652318" d="M5.513 12.811h395.993v29.47H5.513z" />
      <path
        fill="#3d160c"
        d="M213.854 445.922h5.4a3.46 3.46 0 003.46-3.46v-34.831a3.46 3.46 0 00-3.46-3.46h-5.4a3.46 3.46 0 00-3.46 3.46v34.831a3.459 3.459 0 003.46 3.46zm-28.748 0h5.399a3.46 3.46 0 003.46-3.46v-34.831a3.46 3.46 0 00-3.46-3.46h-5.399a3.46 3.46 0 00-3.46 3.46v34.831a3.46 3.46 0 003.46 3.46z"
      />
    </svg>
  );
}
